import {
  HomeIcon,
  UsersIcon,
  UserPlusIcon,
  BookOpenIcon,
  PencilIcon,
  UserGroupIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
  PhoneArrowUpRightIcon,
  FolderPlusIcon,
  CheckBadgeIcon,
  PhotoIcon,
  BuildingOfficeIcon,
  PhoneIcon,
  HeartIcon,
  TrashIcon,
  BeakerIcon,
  SpeakerWaveIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline';
import { ContextUser } from './types/user';
import { ROLES, USER_STATUS } from './util/constant';
import { FaPhotoFilm } from 'react-icons/fa6';
import { MicrophoneIcon } from '@heroicons/react/20/solid';

type ContextObject = {
  user?: ContextUser;
  token?: string;
  login: (user: ContextUser, token: string) => void;
  logout: () => void;
};

export default function getNavigation(context: ContextObject) {
  let sidebarNav = null;
  let isAuthorizedSuperAdmin = false;
  let isAuthorizedUser = false;

  if (context && context.user) {
    isAuthorizedSuperAdmin =
      [ROLES.admin, ROLES.super].includes(context.user.role) &&
      context.user.status === USER_STATUS.clear;
    isAuthorizedUser =
      [ROLES.user].includes(context.user.role) &&
      context.user.status === USER_STATUS.clear;
  }

  if (isAuthorizedSuperAdmin) {
    sidebarNav = [
      {
        name: 'Dashboard',
        to: '/dashboard',
        icon: <HomeIcon className='h-6 w-6 text-gray-300' />,
        items: [],
      },
      {
        name: 'Users',
        to: '#',
        icon: <UsersIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Users',
            to: '/dashboard/users',
            icon: <UserGroupIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New user',
            to: '/dashboard/users/new',
            icon: <UserPlusIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Blog',
        to: '#',
        icon: <FolderPlusIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Blog posts',
            to: '/dashboard/blog-posts',
            icon: <BookOpenIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New blog post',
            to: '/dashboard/blog-posts/new',
            icon: <PencilIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'Blog categories',
            to: '/dashboard/blog-categories',
            icon: <PencilIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New blog category',
            to: '/dashboard/blog-categories/new',
            icon: <PencilIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Assets',
        to: '#',
        icon: <PhotoIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Assets',
            to: '/dashboard/assets',
            icon: <FaPhotoFilm className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New asset',
            to: '/dashboard/assets/new',
            icon: <PhotoIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Recruitment',
        to: '#',
        icon: <WrenchIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Recruitment',
            to: '/dashboard/recruitments',
            icon: <WrenchIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New recruitment',
            to: '/dashboard/recruitments/new',
            icon: <WrenchScrewdriverIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'About us',
        to: '#',
        icon: <BuildingOfficeIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'About us',
            to: '/dashboard/company-abouts',
            icon: <TrashIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New about us',
            to: '/dashboard/company-abouts/new',
            icon: <BeakerIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Cultures',
        to: '#',
        icon: <BuildingOfficeIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Cultures',
            to: '/dashboard/company-cultures',
            icon: <HeartIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New culture',
            to: '/dashboard/company-cultures/new',
            icon: <WrenchScrewdriverIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Services',
        to: '#',
        icon: <WrenchScrewdriverIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Services',
            to: '/dashboard/services',
            icon: <WrenchScrewdriverIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New service',
            to: '/dashboard/services/new',
            icon: <WrenchIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Contacts',
        to: '#',
        icon: <PhoneIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Contacts',
            to: '/dashboard/contacts',
            icon: <PhoneArrowUpRightIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New contact',
            to: '/dashboard/contacts/new',
            icon: <EnvelopeIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Testimonials',
        to: '#',
        icon: <SpeakerWaveIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Testimonials',
            to: '/dashboard/testimonials',
            icon: <SpeakerWaveIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New testimonial',
            to: '/dashboard/testimonials/new',
            icon: <MicrophoneIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Employees',
        to: '#',
        icon: <UsersIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Employees',
            to: '/dashboard/employees',
            icon: <UserGroupIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New employee',
            to: '/dashboard/employees/new',
            icon: <UserPlusIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Customers',
        to: '#',
        icon: <UsersIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Customers',
            to: '/dashboard/customers',
            icon: <UserGroupIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
          {
            name: 'New customer',
            to: '/dashboard/customers/new',
            icon: <UserPlusIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
      {
        name: 'Trust badges',
        to: '#',
        icon: <CheckBadgeIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'New trust badge',
            to: '/dashboard/trust-badges/new',
            icon: <CheckBadgeIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
    ];
  }

  if (isAuthorizedUser) {
    sidebarNav = [
      {
        name: 'Dashboard',
        to: '/dashboard',
        icon: <HomeIcon className='h-6 w-6 text-gray-300' />,
        items: [],
      },
      {
        name: 'Users',
        to: '#',
        icon: <UsersIcon className='h-6 w-6 text-gray-300' />,
        items: [
          {
            name: 'Users',
            to: '/dashboard/users',
            icon: <UserGroupIcon className='h-6 w-6 text-gray-300' />,
            items: [],
          },
        ],
      },
    ];
  }

  return sidebarNav;
}
